import { inject, Injectable } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DateTimeService {
  private translateService = inject(TranslateService);

  getMonthsNames() {
    return moment.months();
  }

  getMonthName(monthNumber: number) {
    return moment.months()[monthNumber];
  }

  getDaysNames(useShort = false) {
    return useShort ? moment.weekdaysShort(true) : moment.weekdays(true);
  }

  getDaysFirstLetters() {
    return this.getDaysNames().map(day => day[0]);
  }

  isFuture(date: Date | string) {
    return moment(date).isAfter(moment());
  }

  getHours(startHour = 0, endHour = 23) {
    const result = [];
    for (let i = startHour; i <= endHour; i += 1) {
      result.push(i);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  }

  getDateInFormat(date: Date | string | number, format: string) {
    return moment(date).format(format);
  }

  getDateCalenderInFormat(date: Date | string) {
    const format = 'HH:mm';
    return moment(date).calendar(null, {
      sameDay: `HH:mm | [${String(
        this.translateService.instant('general.today_l')
      )}]`,
      nextDay: format,
      nextWeek: format,
      lastDay: `HH:mm | [${String(
        this.translateService.instant('general.yesterday_l')
      )}]`,
      lastWeek: format,
      sameElse: format
    });
  }

  getTimeFromISOString(dateTime: string) {
    const date = moment(dateTime, 'yyyy-MM-DDTHH:mm:ss.SSSZ');

    if (!date.isValid()) return null;

    const minutes = `0${date.toDate().getMinutes()}`.slice(-2);
    const hours = `0${date.toDate().getHours()}`.slice(-2);
    return `${hours}:${minutes}`;
  }

  getFullDateAsISOString(date: string, time: string) {
    const hour = +time?.split(':')[0];
    const minutes = +time?.split(':')[1];
    const asString = moment(date).hour(hour).minutes(minutes).toISOString(true);
    const splitOffset = asString?.split('+');
    let output = splitOffset ? splitOffset[0] : null;
    if (splitOffset?.length === 2) {
      const offsetNormalized = splitOffset[1].replace(':', '');
      output = output.concat('+' + offsetNormalized);
    }
    return output;
  }

  getTimeFromSeconds(seconds: number, pattern: string) {
    return moment.utc(1000 * seconds).format(pattern);
  }
}

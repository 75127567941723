<div
  class="flat-select"
  [ngClass]="{ 'flex-wrap': options?.length > 4 || wrapItems }"
>
  @for (option of options; track option; let i = $index) {
    <div
      class="flat-select__item"
      [ngClass]="{
        'flat-select__item--no-stretch': options?.length > 3 || !stretch,
        active: isActive(option),
        readOnly: readonly && isActive(option),
        disabled:
          disabled || (readonly && !isActive(option)) || option.disabled,
        'd-none': option?.value?.id === 'default'
      }"
    >
      <div class="flat-select__label-container">
        <span
          class="flat-select__item-label"
          [ngClass]="{ 'can-be-deleted': deletable }"
          (click)="selectOption(option)"
        >
          <div class="d-flex align-items-center justify-content-center">
            @if (icon) {
              <div
                [class]="getIconClassName(icon)"
                class="flat-select__svg-icon"
              ></div>
            }
            <div>{{ returnValidLabel(option) | translate }}</div>
          </div>
        </span>
        @if (deletable && (option?.value?.template || option?.value?.name)) {
          <app-button
            (clickEvent)="onRemove(i)"
            [elevationHoverEffect]="false"
            [type]="'light-bordered'"
            [borderRadius]="'none'"
            [elevationHoverEffect]="true"
          >
            <div class="icon icon--delete"></div>
          </app-button>
        }
      </div>
    </div>
  }
  @if (showAddButton) {
    <app-button
      class="ghost flat-select__add-button"
      [iconLeft]="'add'"
      [type]="'light-bordered'"
      [disabled]="readonly"
      (clickEvent)="add()"
    >
      {{ addButtonText | translate }}
    </app-button>
  }
</div>

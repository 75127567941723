import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { DOCUMENT, Location, NgClass, AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import {
  ChatComponent,
  ChatContactListComponent
} from 'libs/components/legacy/messenger/components';
import {
  Conversation,
  ConversationDetails,
  ConversationInput,
  ConversationMessage,
  handheldBreakingPoints,
  MessageTemplate,
  MessengerFilter,
  PaneType,
  SendMessageInput
} from 'libs/components/legacy/messenger/model/interface';
import { MessengerFilterService } from 'libs/components/legacy/messenger/services/messenger-filter.service';
import { DownloadService, WINDOW_REF } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import {
  Attachment,
  ContactType,
  CookiePreference,
  CustomerSettings,
  LandlordUser,
  LandlordUserTypes,
  Pagination,
  Property,
  PropertySearcherUser
} from '@ui/shared/models';
import { ActionState } from 'libs/state-utils';

import { combineLatest, Observable, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  pairwise,
  startWith
} from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../atoms/button/button.component';
import { ChatComponent as ChatComponent_1 } from './components/chat/chat.component';
import { ChatContactListComponent as ChatContactListComponent_1 } from './components/chat-contact-list/chat-contact-list.component';

@UntilDestroy()
@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  animations: [
    trigger('slide', [
      state(PaneType.CONTACT_LIST, style({ transform: 'translateX(0%)' })),
      state(PaneType.CHAT, style({ transform: 'translateX(-33.3333333%)' })),
      state(PaneType.INFO, style({ transform: 'translateX(-66.6666666%)' })),
      transition('* => *', animate(300))
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    ChatContactListComponent_1,
    ChatComponent_1,
    ButtonComponent,
    AsyncPipe,
    TranslateModule
  ]
})
export class MessengerComponent implements OnInit, AfterViewInit, OnDestroy {
  private store = inject<Store<fromBaseState.MessengerState>>(Store);
  private downloadService = inject(DownloadService);
  private observer = inject(BreakpointObserver);
  private messengerFilterService = inject(MessengerFilterService);
  private renderer = inject(Renderer2);
  private cdr = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);
  private location = inject(Location);
  private doc = inject(DOCUMENT);
  private windowRef = inject(WINDOW_REF);

  @Input() userData: PropertySearcherUser | LandlordUser;
  @Input() customerSettings: CustomerSettings;
  @Input() sender: ContactType;
  @Input() agentsFilter: LandlordUser[];
  @Input() canAnswer: boolean;
  @Input() templates: MessageTemplate[];
  @Input() propertyData: Property;
  @Input() isApplication: boolean;
  @Input() conversationDetails: ConversationDetails;
  @Input() messagesActionState: ActionState;
  @Output() sendMessage = new EventEmitter<ConversationMessage>();
  @Output() openPropertyModal = new EventEmitter<string>();
  @Output() openChatSettingsModal = new EventEmitter();
  @Output() openCreateConversation = new EventEmitter();
  @Output() createMessageAsTemplate = new EventEmitter<MessageTemplate>();
  @Output() scrollBottom = new EventEmitter();

  public messages$: Observable<ConversationMessage[]>;
  public messagesCount$: Observable<number>;
  public contactListActionState$: Observable<ActionState>;
  public contactList$: Observable<Conversation[]>;
  public contactListPage$: Observable<Pagination>;
  public conversationMessagesPagination$: Observable<Pagination>;
  public totalMessageCount$: Observable<number>;
  public messagesAttach$: Observable<boolean>;
  public template$: Observable<MessageTemplate[]>;
  public searchedProperty$: Observable<Property>;

  public activeConversation: Conversation;
  public page: number;
  public activePane = PaneType.CONTACT_LIST;
  public isMobile = false;
  public isTablet = false;
  public paneTypes = PaneType;
  public destroy$ = new Subject<void>();
  public iosHeight = 0;
  private messengerFilter: MessengerFilter;
  private breakingResult: BreakpointState;
  private cookiePreference: CookiePreference;
  private contacts: HTMLElement;
  private chatInput: HTMLElement;
  private chatContainer: HTMLElement;
  private navbar: HTMLElement;
  private offsetTopContacts = 0;
  private offsetTopChatContainer = 0;
  private resizeListener: any;
  private applicationId: string;
  private ticketId: string;

  public loadArchivedConversationsToggle = false;

  private unsubscribeUnreadMessageLoading$ = new Subject<void>();

  @ViewChild(ChatContactListComponent)
  conversationListComponent: ChatContactListComponent;
  @ViewChild(ChatComponent) chatComponent: ChatComponent;

  public get isPropertySearcher() {
    return this.sender === ContactType.PROPERTY_SEARCHER;
  }

  public get isLandlord() {
    return this.sender === ContactType.AGENT;
  }

  public get isAgent() {
    return this.userData?.usertype === LandlordUserTypes.EMPLOYEE;
  }

  public ngOnInit() {
    this.renderer.addClass(this.doc.body, 'increase-margin');
    const filters = this.isLandlord
      ? {
          agents: this.route.snapshot.data.userFilter?.agentIds,
          conversationExists:
            this.route.snapshot.queryParams.conversationExists,
          propertyId: this.route.snapshot.params.propertyId
        }
      : {};
    this.applicationId = this.route.snapshot.queryParams.applicationId;
    this.ticketId = this.route.snapshot.queryParams.ticketId;

    this.messengerFilterService.init(filters);
    this.messengerFilterService
      .getFilter()
      .pipe(untilDestroyed(this))
      .subscribe(messengerFilter => {
        this.messengerFilter = messengerFilter;

        if (messengerFilter?.conversationExists !== 'true') {
          this.loadConversations();
        }
      });

    this.store
      .select(fromBaseState.getCookiesPreference)
      .pipe(delay(500), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        this.cookiePreference = value;
        this.onCalcHeight();
      });

    this.store
      .select(fromBaseState.getConversationMessagesPage)
      .pipe(untilDestroyed(this))
      .subscribe(page => (this.page = page.page));

    this.contactList$ = this.store.select(fromBaseState.getConversationList);
    this.contactListPage$ = this.store.select(
      fromBaseState.getConversationListPage
    );
    this.contactListActionState$ = this.store.select(
      fromBaseState.getConversationListActionState
    );
    this.messages$ = this.store.select(fromBaseState.getConversationMessages);
    this.conversationMessagesPagination$ = this.store.select(
      fromBaseState.getConversationMessagesPage
    );
    this.totalMessageCount$ = this.store.select(
      fromBaseState.getConversationMessagesCount
    );
    this.messagesAttach$ = this.store.select(
      fromBaseState.getConversationMessagesAttach
    );
    this.messagesCount$ = this.store.select(fromBaseState.getMessageCount);
    this.template$ = this.store.select(fromBaseState.getParsedMessageTemplates);
    this.searchedProperty$ = this.store.select(
      fromBaseState.getSearchedProperty
    );

    this.observer.observe(handheldBreakingPoints).subscribe(result => {
      if (
        result.breakpoints[Breakpoints.HandsetLandscape] ||
        result.breakpoints[Breakpoints.HandsetPortrait]
      ) {
        this.isMobile = true;
        if (this.activeConversation) {
          this.onSetActivePane(this.paneTypes.CHAT);
        }
      } else {
        this.isMobile = false;
      }

      if (
        result.breakpoints[Breakpoints.TabletPortrait] ||
        result.breakpoints[Breakpoints.TabletLandscape]
      ) {
        this.isTablet = true;
        if (this.activeConversation) {
          this.onSetActivePane(this.paneTypes.CHAT);
        }
      } else {
        this.isTablet = false;
      }
    });

    this.store
      .select(fromBaseState.getConversationArchived)
      .pipe(untilDestroyed(this))
      .subscribe(res => (this.loadArchivedConversationsToggle = res));

    this.store
      .select(fromBaseState.getActiveConversation)
      .pipe(startWith(undefined), pairwise(), untilDestroyed(this))
      .subscribe(([prevConv, currConv]) => {
        this.activeConversation = currConv;
        if (
          !currConv ||
          prevConv?.id === currConv?.id ||
          currConv.id === 'new'
        ) {
          return;
        }
        this.store.dispatch(
          new fromBaseState.LoadConversationMessagesAndDetails({
            conversationId: currConv.id,
            customerSettings: {
              dkLevelCustomerSettings:
                this.customerSettings?.dkLevelCustomerSettings,
              obscurePropertySearcherScore:
                this.customerSettings?.obscurePropertySearcherScore
            }
          })
        );
      });
  }

  public ngAfterViewInit(): void {
    /* eslint-disable */
    this.contacts = this.conversationListComponent?.contacts
      ?.nativeElement as HTMLElement;
    this.chatInput = this.chatComponent?.chatInput
      ?.nativeElement as HTMLElement;
    this.chatContainer = this.chatComponent?.chatContainerArea
      ?.nativeElement as HTMLElement;
    this.navbar = this.doc.body.getElementsByClassName(
      'navbar-nav'
    )[0] as HTMLElement;
    combineLatest([
      this.observer.observe([...handheldBreakingPoints, Breakpoints.Web]),
      this.contactListActionState$
    ])
      .pipe(
        filter(([_, stateConversationList]) => !stateConversationList.pending),
        untilDestroyed(this)
      )
      .subscribe(([result]) => {
        this.breakingResult = result;
        this.onCalcHeight();
      });

    this.resizeListener = this.windowRef.addEventListener('resize', () => {
      this.onCalcHeight();
    });

    this.onCalcHeight();
    /* eslint-enable */
  }

  public ngOnDestroy(): void {
    /* eslint-disable */
    // here we unsubscribe from the stream before stopping unread-message polling (see description above
    // in subscription logic).
    this.unsubscribeUnreadMessageLoading$.next();
    this.unsubscribeUnreadMessageLoading$.complete();
    this.store.dispatch(new fromBaseState.StopFindUnreadMessagesPolling());
    this.store.dispatch(new fromBaseState.RemoveConversation('new'));
    this.store.dispatch(new fromBaseState.DeselectConversation());
    this.renderer.removeClass(this.doc.body, 'prevent-scroll');
    this.windowRef.removeEventListener('resize', this.resizeListener);
    this.messengerFilterService.reset();
    this.removeSearchedProperty();
    /* eslint-enable */
  }

  private _getPixelString(value: string): string {
    return `${value}px`;
  }

  public onCalcHeight() {
    this.offsetTopContacts = this.getOffset(this.contacts);
    this.offsetTopChatContainer = this.getOffset(this.chatContainer);
    const body = this.doc.body;
    const calculatedHeight: number = body.clientHeight - this.iosHeight;
    this.contacts.style.height = this._getPixelString(
      String(calculatedHeight - this.offsetTopContacts)
    );
    this.chatContainer.style.height = this._getPixelString(
      String(calculatedHeight - this.offsetTopChatContainer)
    );
    if (
      this.breakingResult?.breakpoints[Breakpoints.HandsetPortrait] ||
      this.breakingResult?.breakpoints[Breakpoints.HandsetLandscape] ||
      this.breakingResult?.breakpoints[Breakpoints.TabletPortrait]
    ) {
      this.contacts.style.height = this._getPixelString(
        String(this.contacts.clientHeight - this.navbar.clientHeight)
      );
      this.chatContainer.style.height = this._getPixelString(
        String(this.chatContainer.clientHeight - this.navbar.clientHeight)
      );

      if (this.sender === ContactType.PROPERTY_SEARCHER) {
        const heightOffset = 63;
        this.contacts.style.height = this._getPixelString(
          String(this.contacts.clientHeight - heightOffset)
        );
        this.chatContainer.style.height = this._getPixelString(
          String(this.chatContainer.clientHeight - heightOffset)
        );
      }
    }
    this.cdr.detectChanges();
  }

  public onSendMessage(data: SendMessageInput) {
    if (this.activeConversation.id === 'new') {
      const payload = {
        ...data,
        dkLevelCustomerSettings: this.customerSettings?.dkLevelCustomerSettings,
        applicationId:
          this.applicationId || this.activeConversation.applicationId,
        ticketId: this.ticketId
      };
      this.store.dispatch(
        new fromBaseState.SendMessageInNewConversation(payload)
      );
    } else {
      this.store.dispatch(
        new fromBaseState.SendMessage(
          {
            ...data,
            conversationId: this.activeConversation.id
          },
          this.loadArchivedConversationsToggle
        )
      );
    }
  }

  public onSelectConversation(conversation: Conversation) {
    if (conversation.id === this.activeConversation?.id) {
      return;
    }

    this.store.dispatch(new fromBaseState.SelectConversation(conversation.id));

    if (this.isMobile || this.isTablet) {
      this.onSetActivePane(PaneType.CHAT);
    }
  }

  public onOpenPropertyModal(propertyId: string) {
    this.openPropertyModal.emit(propertyId);
  }

  public onOpenSettings() {
    this.openChatSettingsModal.emit();
  }

  public onReloadMessenger() {
    this.store.dispatch(new fromBaseState.RefetchCountUnread());
    this.messengerFilterService.reset({
      propertyId: this.messengerFilter.propertyId
    });
  }

  public onFetchOldMessages() {
    this.store.dispatch(
      new fromBaseState.IncreaseChatPage({
        dkLevelCustomerSettings: this.customerSettings?.dkLevelCustomerSettings,
        obscurePropertySearcherScore:
          this.customerSettings?.obscurePropertySearcherScore
      })
    );
  }

  public onDownloadDocument(attachment: Attachment) {
    this.downloadService.downloadByUrl(attachment);
  }

  public onPreviewDocumentInNewTab(attachment: Attachment) {
    this.downloadService.openPdfInNewTab(attachment);
  }

  public onSetActivePane(pane: PaneType) {
    this.activePane = pane;
    this.renderer.addClass(this.doc.body, 'prevent-scroll');
    this.doc.body.scrollTo(0, 0);
    if (pane === PaneType.CONTACT_LIST) {
      this.store.dispatch(new fromBaseState.DeselectConversation());
      this.renderer.removeClass(this.doc.body, 'prevent-scroll');
    }
    if (pane === PaneType.INFO) {
      this.renderer.removeClass(this.doc.body, 'prevent-scroll');
    }

    if (pane === PaneType.CHAT) {
      this.windowRef.scrollTo(0, 0);
    }
  }

  public onAddConversation() {
    this.openCreateConversation.emit();
  }

  public onCreateMessageAsTemplate(template: MessageTemplate) {
    this.createMessageAsTemplate.emit(template);
  }

  public onScrollBottom() {
    this.scrollBottom.emit();
  }

  public onSetNoActiveConversation() {
    this.store.dispatch(new fromBaseState.DeselectConversation());
    this.activeConversation = undefined;
    this.page = undefined;
    this.loadConversations();
  }

  private getOffset = (element): number => {
    if (!element) return 0;
    return this.getOffset(element.offsetParent) + Number(element.offsetTop);
  };

  public loadArchivedConversations() {
    this.loadArchivedConversationsToggle = true;
    this.messengerFilterService.setPage(0);
  }

  public loadActiveConversations() {
    this.loadArchivedConversationsToggle = false;
    this.messengerFilterService.setPage(0);
  }

  public removePropertyFilter() {
    this.removeSearchedProperty();
    this.location.replaceState('messenger');
  }

  private removeSearchedProperty() {
    this.store.dispatch(new fromBaseState.SearchedProperty(undefined));
  }

  /**
   * This function correctly applies all filter params for fetching the conversation list
   * @param data with this param the default settings can be overwritten
   * @private
   */
  private loadConversations(data?: ConversationInput) {
    this.store.dispatch(
      new fromBaseState.LoadConversations({
        dkLevelCustomerSettings: this.customerSettings?.dkLevelCustomerSettings,
        archivedByCustomerOnly: this.loadArchivedConversationsToggle,
        ...this.messengerFilter,
        ...data
      })
    );
  }
}

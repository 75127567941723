import { Injectable, inject } from '@angular/core';

import { ActivatedRouteSnapshot } from '@angular/router';

import { PermissionService } from './permission.service';

interface PermissionData {
  permission: string[];
}

@Injectable()
export class PermissionResolver {
  private permissionService = inject(PermissionService);

  resolve(route: ActivatedRouteSnapshot) {
    const routeData = route.data as PermissionData;

    if (!routeData || !routeData.permission) {
      throw new Error(
        `Cannot resolve route ${String(route.url)}: missing permission name`
      );
    }

    const permittedAddons = {};

    routeData.permission.forEach(
      addon =>
        (permittedAddons[addon] = this.permissionService.hasPermission(addon))
    );

    return permittedAddons;
  }
}

import { Injectable, inject } from '@angular/core';
import {
  District,
  MarketingType,
  ObjectType,
  PropertyType,
  SearchProfile,
  SearchProfilePatchedData,
  SearchProfileType
} from '@ui/shared/models';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { zipCodeValidator } from 'libs/components/legacy/form';
import { GERMAN_COUNTRY_CODE } from 'libs/config/country-config';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getCityDistrictsMap } from 'libs/infrastructure/base-state/search-profiles/search-profiles.selectors';
import { map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage,
  isPropertyTypeWithDistrict
} from 'libs/utils';

@Injectable()
export class SearchProfileService {
  private fb = inject(FormBuilder);
  private translate = inject(TranslateService);
  private store = inject(Store);

  private _registration: boolean;
  private _minUpperBoundRentConfig: Record<PropertyType, number> = {
    COMMERCIAL: 100,
    FLAT: 100,
    GARAGE: 10
  };
  private _maxUpperBoundRentConfig: Record<PropertyType, number> = {
    COMMERCIAL: 8000,
    FLAT: 2500,
    GARAGE: 500
  };
  private _stepsUpperBoundRentConfig: Record<PropertyType, number> = {
    COMMERCIAL: 50,
    FLAT: 50,
    GARAGE: 10
  };

  public set registration(value: boolean) {
    this._registration = value;
  }

  public get districtsFormGroup() {
    return this.fb.group({
      city: this.fb.group({
        name: ['', Validators.required],
        id: ''
      }),
      districts: [[], Validators.required]
    });
  }

  public get searchProfileForm() {
    return this.fb.group({
      id: [''],
      marketingType: MarketingType.RENT,
      name: ['', this._registration ? null : Validators.required],
      address: this.fb.group({
        city: [{ value: '', disabled: true }, Validators.required],
        zipCode: this.fb.control(
          { value: '', disabled: true },
          {
            validators: Validators.compose([
              Validators.required,
              zipCodeValidator
            ]),
            updateOn: 'blur'
          }
        ),
        district: [''],
        street: [''],
        houseNumber: [''],
        country: [GERMAN_COUNTRY_CODE]
      }),
      districts: this.fb.array([this.districtsFormGroup], Validators.required),
      radius: 2000,
      type: SearchProfileType.DISTRICT,
      lowerBoundSize: 10,
      lowerBoundRooms: 1,
      upperBoundRent: 100,
      balconyTerrace: null,
      barrierFree: null,
      seniorApartment: null,
      flatData: this.fb.group({
        objectTypes: [[], Validators.required],
        flatTypes: [{ value: [], disabled: true }, Validators.required],
        houseTypes: [{ value: [], disabled: true }, Validators.required]
      }),
      propertyType: 'FLAT',
      garageTypes: [null],
      commercialData: this.fb.group({
        commercialType: [{ value: null, disabled: true }, Validators.required],
        commercialSubTypes: [{ value: [], disabled: true }, Validators.required]
      }),
      durationEndDate: null,
      salesData: this.fb.group({
        priceUpperBound: 5000
      }),
      deactivated: false,
      projectId: null,
      searchingSince: [new Date(), Validators.required],
      elevator: null,
      ignoreFloorIfElevatorExists: false,
      lowerBoundFloor: 0,
      upperBoundFloor: 10,
      createdByPS: false
    });
  }

  /**
   * Applies/changes business logic when propertyType or objectTypes changes
   * Unifies the default logic changes in one function/place
   * @param form
   */
  public listenAndApplyDefaultFormChanges(form: FormGroup) {
    return combineLatest([
      form
        .get('propertyType')
        .valueChanges.pipe(
          map(propertyType =>
            this.handlePropertyTypeChanges(form, propertyType)
          )
        ),
      form
        .get('flatData.objectTypes')
        .valueChanges.pipe(
          map((objectTypes: string[]) =>
            this.handleFlatDataObjectTypesChanges(
              form.get('flatData') as FormGroup,
              objectTypes
            )
          )
        )
    ]);
  }

  public extractSearchProfileDataPayload(
    searchProfile: SearchProfilePatchedData,
    id?: string
  ) {
    const {
      barrierFree,
      balconyTerrace,
      seniorApartment,
      districts,
      name,
      landlordDistricts,
      lowerBoundFloor,
      upperBoundFloor,
      ...rest
    } = searchProfile;

    const searchProfileDistricts = [];

    if (districts)
      searchProfileDistricts.push(
        ...districts
          ?.flatMap(({ districts }) => districts)
          .map(value => ({
            id: typeof value === 'string' ? value : value.id
          }))
      );

    if (landlordDistricts)
      searchProfileDistricts.push(
        ...landlordDistricts.flat().map(id => ({ id }))
      );

    const searchProfileData: SearchProfile = {
      ...rest,
      id,
      districts: searchProfileDistricts || null,
      name: name || this.translate.instant('search_profile.name_l'),
      lowerBoundFloor: lowerBoundFloor === 0 ? null : lowerBoundFloor,
      upperBoundFloor: upperBoundFloor >= 10 ? null : upperBoundFloor
    };

    // These values should only be sent if they are true or false, otherwise do not send them
    const checkValues = {
      balconyTerrace,
      barrierFree,
      seniorApartment
    };

    // Adding the non-null values to searchProfileData
    Object.keys(checkValues).forEach(key => {
      if (checkValues[key] !== null) {
        searchProfileData[key] = checkValues[key];
      }
    });

    return searchProfileData;
  }

  public patchForm(
    searchProfile: SearchProfile,
    districtsFormArray: FormArray
  ) {
    const { districts, lowerBoundFloor, upperBoundFloor, ...rest } =
      searchProfile;
    const districtsMap = new Map<string, District[]>();
    if (districts.length) districtsFormArray.clear();

    // Create the map
    districts.forEach(district => {
      if (districtsMap.get(district.cityName)) {
        districtsMap.set(district.cityName, [
          ...districtsMap.get(district.cityName),
          district
        ]);
      } else {
        districtsMap.set(district.cityName, [district]);
        districtsFormArray.push(this.districtsFormGroup);
      }
    });

    // update districts according to the map
    const districtsToPatch = [];
    districtsMap.forEach((districts, cityName) => {
      const { cityId } = districts[0];

      districtsToPatch.push({
        city: {
          id: cityId,
          name: cityName
        },
        districts
      });
    });

    return {
      ...rest,
      lowerBoundFloor: lowerBoundFloor === null ? 0 : lowerBoundFloor,
      upperBoundFloor: upperBoundFloor === null ? 10 : upperBoundFloor,
      districts: districtsToPatch
    };
  }

  public getSearchProfileInfoPayload(
    searchProfile: SearchProfilePatchedData
  ): Observable<SearchProfile> {
    return this.store.select(getCityDistrictsMap).pipe(
      take(1),
      map(cityDistrictsMap => {
        const {
          districts: searchProfileDistricts = [],
          ...rest
        }: SearchProfilePatchedData = searchProfile;
        const districtsToPatch: District[] = [];

        searchProfileDistricts.forEach(
          ({ districts, city: { name: cityName, id: cityId } }) => {
            districts.map(district => {
              const districtId =
                typeof district === 'string' ? district : district.id;
              districtsToPatch.push({
                cityId,
                cityName,
                id: districtId,
                name:
                  district.name ||
                  cityDistrictsMap
                    .get(cityId)
                    .find(district => district.id === districtId).name
              });
            });
          }
        );

        return {
          ...rest,
          districts: districtsToPatch
        };
      })
    );
  }

  public disableNonProjectRelatedFormFields(formGroup: FormGroup) {
    formGroup.get('name').disable();
    formGroup.get('address').disable();
    formGroup.get('districts').disable();
    formGroup.get('radius').disable();
  }

  public handlePropertyTypeChanges(
    formGroup: FormGroup,
    propertyType: PropertyType
  ) {
    const isProjectSearchProfile =
      formGroup.get('type').value === SearchProfileType.PROJECT;

    if (isPropertyTypeFlat(propertyType)) {
      formGroup.get('commercialData').disable({ emitEvent: false });
      formGroup.get('garageTypes').disable();
      if (formGroup.get('flatData').disabled) {
        formGroup.get('flatData').reset();
        formGroup.get('flatData').enable();
      }
    }

    if (isPropertyTypeCommercial(propertyType)) {
      formGroup.get('flatData').disable({ emitEvent: false });
      formGroup.get('garageTypes').disable();
      formGroup.get('commercialData').enable({ emitEvent: false });
    }

    if (isPropertyTypeGarage(propertyType)) {
      formGroup.get('flatData').disable({ emitEvent: false });
      formGroup.get('commercialData').disable({ emitEvent: false });
      formGroup.get('garageTypes').enable();
    }

    if (isPropertyTypeWithDistrict(propertyType)) {
      formGroup.get('districts').enable();
    } else {
      formGroup.get('districts').disable();
    }

    if (isProjectSearchProfile) {
      this.disableNonProjectRelatedFormFields(formGroup);
    }

    // The form controls need to be moved after calling handlePropertyTypeChanges,
    // because the function will reenable the districts
    if (formGroup.get('type').value === SearchProfileType.RADIUS) {
      formGroup.get('address.city').enable();
      formGroup.get('address.zipCode').enable();
      formGroup.get('districts').disable();
    }

    this.checkUpperBoundPrice(
      formGroup.get('upperBoundRent'),
      this.getMinUpperBoundRent(propertyType),
      this.getMaxUpperBoundRent(propertyType)
    );
  }

  public handleFlatDataObjectTypesChanges(
    flatDataGroup: FormGroup,
    objectTypes: string[]
  ) {
    if (!objectTypes) return;
    const isEmptyTypes = objectTypes.length === 0;
    const isFlatIncluded = objectTypes.includes(ObjectType.FLAT);
    const isHouseIncluded = objectTypes.includes(ObjectType.HOUSE);
    const areBothIncluded = isFlatIncluded && isHouseIncluded;

    if (isEmptyTypes) {
      flatDataGroup.get('flatTypes').disable();
      flatDataGroup.get('houseTypes').disable();
      return;
    }

    if (areBothIncluded) {
      flatDataGroup.get('flatTypes').enable();
      flatDataGroup.get('houseTypes').enable();
      return;
    }

    if (isFlatIncluded) {
      flatDataGroup.get('flatTypes').enable();
      flatDataGroup.get('houseTypes').disable();
      return;
    }

    if (isHouseIncluded) {
      flatDataGroup.get('houseTypes').enable();
      flatDataGroup.get('flatTypes').disable();
      return;
    }
  }

  getMinUpperBoundRent(type: PropertyType) {
    return this._minUpperBoundRentConfig[type];
  }

  getMaxUpperBoundRent(type: PropertyType) {
    return this._maxUpperBoundRentConfig[type];
  }

  getStepsForUpperBoundRent(type: PropertyType) {
    return this._stepsUpperBoundRentConfig[type];
  }

  /**
   * Checks if upperBoundRent exceeds the bound. If yes then it sets the value to the nearest bound value
   * @param upperBoundRent
   * @param lowerBound
   * @param upperBound
   */
  public checkUpperBoundPrice(
    upperBoundRent: AbstractControl,
    lowerBound: number,
    upperBound: number
  ) {
    const upperBoundRentValue = upperBoundRent.value;
    if (!upperBoundRentValue) return;

    if (upperBoundRentValue < lowerBound) {
      upperBoundRent.setValue(lowerBound);
    } else if (upperBoundRentValue > upperBound) {
      upperBoundRent.setValue(upperBound);
    }
  }
}

<div
  class="contact-list__contact-item"
  [class.active_chat]="activeConversation?.id === conversation?.id"
  (click)="onSelectConversation(conversation)"
>
  <div class="contact-list__contact d-flex justify-content-center">
    <div class="contact-list__contact-img col-2 p0">
      <div class="contact-list__contact-img-inner">
        @if (conversation?.titleImage || isPropertySearcher) {
          <div class="object-image">
            <img
              [src]="
                conversation?.titleImage ||
                '/assets/images/no-data-illustration.svg'
              "
              alt=""
            />
          </div>
        }
        @if (
          conversation?.mainConversationPartner &&
          (isLandlord ||
            conversation?.mainConversationPartner?.type === 'AGENT')
        ) {
          <app-avatar
            [name]="conversation.mainConversationPartner | appFullName"
            [imgSrc]="conversation.mainConversationPartner.portrait"
            [size]="
              conversation.titleImage || isPropertySearcher
                ? AvatarSizeEnum.TINY
                : AvatarSizeEnum.SMALL
            "
            [class.small]="conversation.titleImage || isPropertySearcher"
          >
          </app-avatar>
        }
      </div>
    </div>
    <div class="contact-list__contact-content col-10">
      <div class="row">
        <div class="position-relative col-9">
          @if (isLandlord) {
            <div class="chat-badge-container">
              @for (label of conversation?.labels; track label) {
                <app-badge
                  [color]="chatBadgeColor[label]?.color"
                  [size]="'small'"
                >
                  {{ chatBadgeColor[label]?.text | translate }}
                </app-badge>
              }
            </div>
          }
          @if (conversation?.unreadMessages > 0) {
            <span class="unread__messages"></span>
          }
          <h5>
            @if (isLandlord) {
              {{ conversation?.mainConversationPartner | appFullName }}
              @if (conversation?.otherParticipants > 1) {
                +{{ conversation?.otherParticipants }}
              }
            }
          </h5>
          <p>{{ conversation?.subject }}</p>
          <p
            class="title-xs"
            [innerHTML]="removeBreaks(conversation?.lastMessageText)"
          ></p>
        </div>
        <div
          class="chat_date col-3"
          [class.unread]="conversation?.unreadMessages > 0"
        >
          @if (isToday(conversation?.lastMessageDate)) {
            {{
              conversation?.lastMessageDate
                | appDateTime: { withDate: false, withTime: true }
            }}
          }
          @if (isLastSevenDays(conversation?.lastMessageDate)) {
            {{
              conversation?.lastMessageDate
                | appDateTime: { customPattern: 'dd' }
            }}
          }
          @if (isInThePast(conversation?.lastMessageDate)) {
            {{
              conversation?.lastMessageDate
                | appDateTime: { customPattern: 'MMM DD' }
            }}
          }
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable, inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastOptions, ToastService } from 'libs/components/legacy/toast';
import { ModalService } from 'libs/components/legacy/modal';

@Injectable()
export class NotificationService {
  private toastService = inject(ToastService);
  private translate = inject(TranslateService);
  private modalService = inject(ModalService);

  public showSuccessToast(message: string, options: ToastOptions = {}) {
    return this.toastService.success(this.translate.instant(message), options);
  }

  public showInfoToast(
    message: string,
    options: ToastOptions = {},
    interpolateParams?: Record<string, unknown>
  ) {
    return this.toastService.info(
      this.translate.instant(message, interpolateParams),
      options
    );
  }

  public showErrorToast(
    message: string,
    options: ToastOptions = {},
    interpolateParams?: Record<string, unknown>
  ) {
    return this.toastService.error(
      this.translate.instant(message, interpolateParams),
      options
    );
  }

  public showWarningToast(message: string, options: ToastOptions = {}) {
    return this.toastService.warning(this.translate.instant(message), options);
  }

  public showInfoModal(
    titleMessage: string,
    message: string,
    okButtonMessage: string = null,
    innerHTML = false
  ) {
    const data = {
      titleMessage: titleMessage,
      message: message,
      okButtonMessage: okButtonMessage,
      innerHTML
    };
    return this.modalService.openAcknowledgement({ data: data });
  }
}

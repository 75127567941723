import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  input,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ButtonTypeEnum } from 'libs/components/atoms/button/button.enum';
import {
  DropdownStepperChildren,
  DropdownStepperParent,
  IconTypeEnum
} from '@ui/shared/models';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { BaseControl } from 'libs/components/legacy/form';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { CheckComponent } from '../../legacy/form/controls/check/check.component';
import { ButtonComponent } from '../button/button.component';
import { IsValueSelectedInDropdownStepperPipe } from './is-value-selected.pipe';

@Component({
  selector: 'app-multi-select-dropdown-stepper',
  templateUrl: './multi-select-dropdown-stepper.component.html',
  styleUrl: './multi-select-dropdown-stepper.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownStepperComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => MultiSelectDropdownStepperComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgTemplateOutlet,
    NgClass,
    NgbDropdownMenu,
    ButtonComponent,
    CheckComponent,
    FormsModule,
    TranslateModule,
    IsValueSelectedInDropdownStepperPipe
  ]
})
export class MultiSelectDropdownStepperComponent<T> extends BaseControl<T[]> {
  items = input.required<DropdownStepperParent<T>[]>();
  dropdownTitle = input.required<string>();
  placeholderText = input.required<string>();
  multipleItemsSelectedText = input.required<string>();
  resetChildrenOnNewParentSelection = input<boolean>(false);
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  selectedParent = signal<DropdownStepperParent<T> | undefined | null>(
    undefined
  );
  selectedChildren = signal<DropdownStepperChildren<T>[] | undefined | null>(
    []
  );
  animationState = 'showParents';
  isTransitioningToManufacturers = false;
  isDropdownOpened = false;

  // Used in combination with resetChildrenOnNewParentSelection
  overwriteChildrenOnNextSelection = false;

  public writeValue(value?: T[]): void {
    super.writeValue(value);
    const selectedChildren = this.findValueInItems(value);
    if (selectedChildren) this.selectedChildren.set(selectedChildren);
  }

  set value(value: T[]) {
    super.value = value;
    const selectedChild = this.findValueInItems(value);
    if (selectedChild) this.selectedChildren.set(selectedChild);
  }

  handleDropdownOpenChange(opened: boolean) {
    if (!opened) this.animationState = 'showParents';
    this.isDropdownOpened = opened;
  }

  selectParent(parent: DropdownStepperParent<T>): void {
    if (this.isTransitioningToManufacturers) return;

    this.animationState = 'showChildren';
    if (parent === this.selectedParent()) {
      return;
    } else if (this.resetChildrenOnNewParentSelection) {
      this.overwriteChildrenOnNextSelection = true;
    }
    this.selectedParent.set(parent);
  }

  selectChild(child: DropdownStepperChildren<T>): void {
    if (this.overwriteChildrenOnNextSelection) {
      if (this.selectedChildren()?.length)
        this.selectedChildren.update(selectedChildren =>
          selectedChildren.filter(child =>
            this.selectedParent().items.some(
              parentChild => parentChild.value === child.value
            )
          )
        );
      this.overwriteChildrenOnNextSelection = false;
    }
    if (
      this.selectedChildren().some(
        selectedChild => selectedChild.value === child.value
      )
    ) {
      this.selectedChildren.update(v =>
        v.filter(selectedChild => selectedChild.value !== child.value)
      );
    } else {
      this.selectedChildren.update(v => [...v, child]);
    }

    this.value = this.selectedChildren().map(child => child.value);
  }

  backToParents(): void {
    this.isTransitioningToManufacturers = true;
    setTimeout(() => {
      // After the animation has finished we want to reset the selected parent
      // If we do this immediately the user would see that the child list looks broken because it's empty
      this.selectedParent.set(undefined);
      this.isTransitioningToManufacturers = false;
    }, 375);
    this.animationState = 'showParents';
  }

  clear(event?: Event): void {
    // When you don't have this, then the dropdown would be opened/closed
    // It stops the parent element from also receiving the click event
    if (event) event.stopPropagation();
    this.value = [];
    this.selectedChildren.set([]);
  }

  private findValueInItems(values: T[]): DropdownStepperChildren<T>[] {
    const children: DropdownStepperChildren<T>[] = [];
    const fl = this.items().flatMap(parent => parent.items);

    values.forEach(value => {
      const child = fl.find(child => child.value === value);
      if (child) children.push(child);
    });

    return children;
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IconTypeEnum = IconTypeEnum;
}

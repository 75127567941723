import { TicketingVersion } from '@ui/shared/models';

export type residentAppSettings = {
  accessRestrictionPeriodMonths: number;
  grantAccessAfterContractExpiration: boolean;
  ticketingVersion: TicketingVersion;
  allowContractlessTicketCreationForDamages: boolean;
  allowContractlessTicketCreationForOtherConcerns: boolean;
  askForMovingAddressOnEndingContract: boolean;
  digitalDocumentsReceivalMandatory: boolean;
};

export enum CatalogueType {
  GENERAL,
  FLAT,
  GARAGE,
  COMMERCIAL,
  NO_CONTRACT
}

enum TicketIssueType {
  DAMAGE,
  CONCERN
}

export type LandlordTicketCatalogue = {
  id: string;
  catalogueType: CatalogueType;
  issueType: TicketIssueType;
  lastUpdate: Date;
  downloadPossible: boolean;
};

import { gql } from 'apollo-angular';
import { paginationQuery } from 'libs/queries/pagination.query';
import {
  addressGQL,
  attachments,
  propertyPsSide,
  rentDepositInfoFields,
  searchProfilesGQL
} from 'libs/infrastructure/base-state/gql-queries';

export const conversationFields = `
    id
    lastMessageDate
    lastMessageText
    unreadMessages
    subject
    titleImage
    mainConversationPartner {
      firstname
      name
      portrait
      dkApprovalLevel
      type
    }
    otherParticipants
    labels
`;

export const conversationMessageFields = `
    id
    conversationId
    messageSent
    message
    sender {
      firstname
      name
      portrait
      dkApprovalLevel
      type
    }
    source
    read
    attachments {
      title
      url
      encrypted
    }
`;

export const searchConversationQuery = gql`
  query searchConversation($input: ConversationInput) {
    searchConversation(input: $input) {
      nodes {
        ${conversationFields}
      }
      page {
        ${paginationQuery}
      }
    }
  }
`;

export const searchConversationMessagesByConversationIdQuery = gql`
  query searchConversationMessagesByConversationId($input: ConversationMessageInput) {
    searchConversationMessagesByConversationId(input: $input) {
      nodes {
        ${conversationMessageFields}
      }
      page {
        ${paginationQuery}
      }
    }
  }
`;

export const conversationMessagesAndDetailsLLQuery = gql`
  query conversationMessagesAndDetails($input: ConversationMessageInput) {
    searchConversationMessagesByConversationId(input: $input) {
      nodes {
        ${conversationMessageFields}
      }
      page {
        ${paginationQuery}
      }
    }

    conversationDetails(input: $input) {
      id
      participants {
        firstname
        name
        portrait
        dkApprovalLevel
        type
        email
        phone
        mobile
      }
      type
      blocked
      applicationDetails {
        isProspect
        id
        created
        score
        scoreObscured
        customQuestionsAnswerOverview
        status
        attendingViewing
        attendingExclusiveViewing
        applicationBlocked
        askedForIntent
        dkApprovalLevel
        searchingSince
        entryDate
        updateStatus
        applicationConversationStatus
        systemTags
        customQuestionsAnswerOverview
        hasComments
        hasCreditReport
        hasIncomeDocument
        hasWbsDocument
        searchingSince
        rating
        text
        profileData {
          firstname
          name
          email
          membershipNumber
          phone
          dateOfBirth
          animals
          householdType
          residents
          furtherInformation
          guarantorExist
          preferredLanguage
          ${addressGQL}
          ${attachments}
          housingPermission {
            type
            amountPeople
          }
          profession {
            income
            subType
            type
          }
          moveInDate
          portrait {
            url
          }
        }
        salesLegalTexts {
          text
          type
        }
        tags {
          id
          title
          tagOwnershipType
        }
        searchProfileBean {
          ${searchProfilesGQL}
        }
        waitingSince
        propertyId
      }
      ticketDetails {
        id
        title
        status
      }
      propertyDetailsLL
    }
  }
`;

export const conversationMessagesAndDetailsPSQuery = gql`
  query conversationMessagesAndDetails($input: ConversationMessageInput) {
    searchConversationMessagesByConversationId(input: $input) {
      nodes {
        ${conversationMessageFields}
      }
      page {
        ${paginationQuery}
      }
    }

    conversationDetails(input: $input) {
      id
      participants {
        firstname
        name
        portrait
        type
      }
      type
      userAllowedToSendMessage
      propertyDetailsPS {
        id
        type
        status
        appointmentSlotsAvailable
        upcomingAppointmentDate
        upcomingAppointmentId
        hasQuestions
        hasUnansweredQuestions
        conversationExists
        allowedToSendMessage
        askForViewingAppointmentAttendance
        rentDepositInfo {
          ${rentDepositInfoFields}
        }
        ${propertyPsSide}
      }
    }
  }
`;

export const sendMessageMutation = gql`
  mutation sendMessageMutation($input: SendMessageInput!) {
    sendMessage(input: $input) {
      ${conversationMessageFields}
    }
  }
`;

export const sendMessageInNewConversationMutation = gql`
  mutation sendMessageInNewConversation($input: SendMessageInput!) {
    sendMessageInNewConversation(input: $input) {
      ${conversationFields}
    }
  }
`;

export const bulkSendMessageMutation = gql`
  mutation bulkSendMessage($input: BulkSendMessageInput!) {
    bulkSendMessage(input: $input) {
      status
      statusText
    }
  }
`;

export const blockConversation = gql`
  mutation blockConversation($input: BlockInput!) {
    blockConversation(input: $input)
  }
`;

export const findUnreadMessages = gql`
  query findUnreadMessages($conversationId: ID!) {
    findUnreadMessages(conversationId: $conversationId) {
      nodes {
        ${conversationMessageFields}
      }
      page {
        ${paginationQuery}
      }
    }
  }

`;

export const countUnread = gql`
  query countUnread {
    countUnread {
      messages
    }
  }
`;

export const loadParticipatedAgentsQuery = gql`
  query loadParticipatedAgents($conversationId: String) {
    loadParticipatedAgents(conversationId: $conversationId) {
      id
      name
      firstName
      usertype
      phone
      email
      portrait {
        title
        type
        url
        identifier
        name
        extension
        encrypted
        index
      }
    }
  }
`;

export const templateQueryFields = `
  id
  title
  content
  attachments {
    title
    type
    url
    identifier
    name
    extension
    encrypted
    index
  }
`;

export const loadTemplateQuery = gql`
  query loadTemplate($page: PaginationInput) {
    loadTemplate(page: $page) {
      nodes {
        ${templateQueryFields}
      },
      page {
        ${paginationQuery}
      }
    }
  }

`;

export const loadParsedTemplatesQuery = gql`
  query loadParsedTemplates($input: LoadParsedTemplateInput) {
    loadParsedTemplates(input: $input) {
      nodes {
        ${templateQueryFields}
      },
      page {
        ${paginationQuery}
      }
    }
  }

`;

export const createTemplateMutation = gql`
  mutation createTemplate($template: MessageTemplateInput) {
    createTemplate(template: $template) {
      id
    }
  }
`;

export const updateTemplateMutation = gql`
  mutation updateTemplate($template: MessageTemplateInput) {
    updateTemplate(template: $template)
  }
`;

export const deleteTemplateMutation = gql`
  mutation deleteTemplate($templateId: String) {
    deleteTemplate(templateId: $templateId)
  }
`;

export const markConversationAsUnreadMutation = gql`
  mutation markConversationAsUnread($id: ID!) {
    markConversationAsUnread(id: $id) {
      status
    }
  }
`;

export const archiveConversationMutation = gql`
  mutation archiveConversation($conversationId: ID!) {
    archiveConversation(conversationId: $conversationId) {
      status
      statusText
    }
  }
`;

export const unarchiveConversationMutation = gql`
  mutation unarchiveConversation($conversationId: ID!) {
    unarchiveConversation(conversationId: $conversationId) {
      status
      statusText
    }
  }
`;
